import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    authenticated: false
};

const authReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.login.END: {
                draft.authenticated = typeof action.data.username !== "undefined";
                draft.auth_error = typeof action.data.error !== "undefined";
                break;
            }
            case actionTypes.login.START: {
                draft.auth_error = false;
                break;
            }
            case actionTypes.login.LOGOUT: {
                draft.authenticated = false;
                break;
            }
            default:
                break;
        }
    });

export default authReducer;
