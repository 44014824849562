import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/i18n";
import "./index.css";
import "./fonts.scss";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";
import applicationReducer from "./store/reducers/application";
import authenticationReducer from "./store/reducers/authentication";
import loginReducer from "./store/reducers/login";
import druidReducer from "./store/reducers/druid";

import ClientAPI from "./ClientAPI/ClientAPI";
import { rootSaga } from "./store/sagas";

import history from "./history";
import playerReducer from "./store/reducers/player";

const rootReducer = combineReducers({
    application: applicationReducer,
    authentication: authenticationReducer,
    login: loginReducer,
    druid: druidReducer,
    player: playerReducer
});

window.config = window.config ?? {environment: "staging",front_url: 'https://exclusion-test2-cristian.78-47-73-37.nip.io/'}; // TODO: remove this object;

const asyncDispatchMiddleware = store => next => action => {
    let syncActivityFinished = false;
    let actionQueue = [];

    function flushQueue() {
        actionQueue.forEach(a => store.dispatch(a)); // flush queue
        actionQueue = [];
    }

    function asyncDispatch(asyncAction) {
        actionQueue = actionQueue.concat([asyncAction]);

        if (syncActivityFinished) {
            flushQueue();
        }
    }

    const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

    const res = next(actionWithAsyncDispatch);
    syncActivityFinished = true;
    flushQueue();

    return res;
};

let storeVariable;
const sagaMiddleware = createSagaMiddleware();
if (window.config.environment === 'staging') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    storeVariable = createStore(
        rootReducer, composeEnhancers(applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)),
    );
} else {
    storeVariable = createStore(
        rootReducer,
        applyMiddleware(thunk, asyncDispatchMiddleware, sagaMiddleware)
    );
}


export const store = storeVariable;

ClientAPI.setStore(store);

sagaMiddleware.run(rootSaga);
window._store = store;
const app = (
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </I18nextProvider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
