import { all, call} from "redux-saga/effects";
import druid from "./druidSaga";
import login from "./login";
import player from "./playerSaga";
import application from "./application";

export function* rootSaga() {
    const sagas = [
        call(druid),
        call(login),
        call(player),
        call(application),
    ];
    yield all(sagas);
}
