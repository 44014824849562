import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translations } from "../i18n/translations";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: translations,
    debug: false, // TODO: this flag let us know if we have translations for all languages
    lng: 'ro', // default language
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: '>', // we use content as keys
    nsSeparator: '|',

    saveMissing: true,

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
}, (err, t) => {
    if (err) return console.log('testare', err);
}).then(function (t) {

});
// i18n.addResourceBundle('ro', 'translation', ro);
// i18n.reloadResources();
//
// i18n.addResourceBundle('ro', 'translations', en);
//
//
// i18n.on('loaded', function(loaded) {
//     const ro = i18n.getFixedT('ro','translations');
//     console.log('testare loaded',ro('Register'));
// })

export const getLanguage = () => i18n.language;

export default i18n;
