import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './ScanResponse.scss';
import TitleBar from '../../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import DruidScanner from "../DruidScanner";
import icon from "../../../assets/icons/safety.png";
import stopHand from "../../../assets/icons/ic_round-back-hand.svg";
import successIcon from "../../../assets/icons/success-icon.svg";
import warningIcon from "../../../assets/icons/icon-warning.svg";

class ScanResponse extends Component {

	state = {
		response: null
	}

	componentDidMount() {

		this.parseUrl(this.props.history.location.pathname);

		this.props.history.listen(location => {
			this.parseUrl(location.pathname);
		});
	}

	parseUrl(pathname) {
		let url = pathname.split('/');

		this.setState({
			...this.state,
			response: url[2],
			type: url[3]
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
			let url = this.props.history.location.pathname.split('/');
			this.setState({
				...this.state,
				response: url[2],
				type: url[3]
			});
		}
	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	getContent() {

		let comp = [];

		switch (this.state.response) {
			case "reading-error":
				comp.push(<div className="result" key={'timeout-1'}>

					<div className="icon"> </div>
					<h1 className="uppercase">{this.props.t("Error reading document!")}</h1>
					<h2 className={"uppercase"}>{this.props.t("Scan error")}</h2>

				</div>);
				comp.push(<div className="buttons" key={'timeout-2'}><DruidScanner type={this.state.type} label={this.props.t('Scan again')}/></div>)
				comp.push(<div className="details" key={'details'}>
					<strong><u>{this.props.t("Possible solutions")}:</u></strong> {this.props.t("să nu fie acoperit")}, {this.props.t("să fie iluminat suficient")}
				</div>)
				comp.push(<div className="safety-badge" key={'safety-badge'}>
					<div className="icon"><img src={icon} alt={this.props.t("100% safe")}/></div>
					<div className="text">{this.props.t("100% safe")}</div>
				</div>)
				comp.push(<NavLink to={'/'} className={'actions'} key={'timeout-3'}>{this.props.t("Main page")}</NavLink>)

				break;
			case "document-invalid":
			case "expired-document":
			case "child-document":
				comp.push(<div className="result" key={'docinvalid-1'}>

					<div className="icon"> {this.state.response === 'child-document' ? (<img src={stopHand} alt={"STOP!"}/>) : "" }</div>
					<h1 className="uppercase">{this.props.t("Error!")}</h1>
					<h2 className={"uppercase"}>
						{this.state.response === 'document-invalid' ?
							this.props.t("Document invalid") :
							(this.state.response === 'child-document' ?
								this.props.t("Age under 18") :
								this.props.t("Expired document"))}
					</h2>

				</div>);
				comp.push(<div className="buttons" key={'timeout-2'}><DruidScanner type={this.state.type} label={this.props.t('Scan again')}/></div>)

				comp.push(<NavLink to={'/'} className={'actions'} key={'timeout-3'}>{this.props.t("Main page")}</NavLink>)

				break;

			case "locked":
				comp.push(<>
					<div className="result" key={'locked-1'}>
						<div className="icon">
							<img src={warningIcon} alt={'No access'}/>
						</div>
						<h1 className="name">{this.props.player.first_name} {this.props.player.last_name}</h1>
						<div className="details">
							<p>{this.props.t("Restricted client")}</p>
							<h2>{this.props.t("The access in location is restricted")}</h2>
						</div>
					</div>
					<div className="exclusion-expire" key={'locked-2'}>
						{this.props.player.exclusion && <>
							<span>{this.props.t('Exclusion expires at')}:</span>
							<div className="time">
								{this.props.player.exclusion}
							</div>
						</>}
						<DruidScanner type={this.state.type} label={this.props.t('New verification')}/>
					</div>
					<NavLink to={'/'} className={'actions'} key={'locked-3'}>{this.props.t("Main page")}</NavLink>
					</>
				);
				break;
			case "success":
				switch (this.state.type) {
					case "access":
						comp.push(<div className="result">
							<div className="icon"><img src={successIcon} alt={'success'}/></div>
							{this.props.player && <h1 className="name">{this.props.player.first_name} {this.props.player.last_name}</h1>}
							<div className="details">
								<p>{this.props.t("Result")}:</p>
								<h2>{this.props.t("Access granted!")}</h2>
							</div>
						</div>);
						comp.push(<div className={'buttons'}>
							<DruidScanner type={this.state.type} label={this.props.t('New verification')}/>
						</div>)
						comp.push(<NavLink to={'/'} className={'actions'} key={'timeout-3'}>{this.props.t("Main page")}</NavLink>)

						break;
					case "exclusion":
						this.props.history.push('/exclusion/2');
						break;
					default:
						// do nothing
						break;

				}

				break;
			default:
				// do nothing
				break;
		}
		return comp;
	}

	render() {
		return (
			<div className={`scan-response ${this.state.response}`}>
				<TitleBar
					pageTitle={this.state.type === "access" ? this.props.t('Status') : this.props.t("Exclusion verification")}
					closeButton={true}
					backButton={false}
				/>
				<div className="container">
					{this.getContent()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication,
		player: state.player.info
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanResponse)));
