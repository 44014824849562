import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './RecentPlayers.scss';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../store/actions";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"

class RecentPlayers extends Component {

    constructor(props) {
        super(props);
        this.perPage = 6;

        if (window.innerHeight < 700 && window.innerHeight >= 605) {
            this.perPage = 5;
        } else if (window.innerHeight < 605 && window.innerHeight >= 510) {
            this.perPage = 4;
        } else if (window.innerHeight < 510 && window.innerHeight >= 415) {
            this.perPage = 3;
        } else if (window.innerHeight < 415) {
            this.perPage = 2;
        }
    }
    state = {
        player: false,
    }
    componentDidMount() {
        this.props.getRecentPlayers(1);
    }


    listPlayers() {
        let comp = [];
        let all = [];
        if (this.props.recent_players) {
            if (this.props.recent_players.rows) {
                this.props.recent_players.rows.forEach((player,key) => {

                    comp.push(this.renderPlayer(player));
                    if (key%this.perPage === this.perPage-1 && key !== 0) {
                        all.push(<div className={'player-slide'}>
                            {comp}
                        </div>)
                        comp = [];
                    }
                })

                if (comp.length) {
                    all.push(<div className={'player-slide'}>
                        {comp}
                    </div>);
                }
            }
        }
        if (all.length === 0) {
            return (<div key={'no-players'}>
                {this.props.t("No player scans found in this timeframe")}
            </div>);
        }else {
            return (
                <Carousel key={'player-scans-list'}>
                    {all}
                </Carousel>
            );
        }
        return all;
    }
    selectPlayer(player) {
        this.setState({
            ...this.state,
            player:player
        })
    }
    renderPlayer(player) {
        return (<div className={'player'} onClick={this.selectPlayer.bind(this,player)} key={player.id}>
            <div className="name">{player.last_name} {player.first_name[0]}.</div>
            <div className="creation-date">{new Date(player.creation_date).toLocaleTimeString(false,{hour: 'numeric', minute: 'numeric',hour12:true})}</div>
            <div className="cnp">{player.cnp}</div>
            <div className="info">
                <div className="years">{Math.floor(Math.abs(new Date() - new Date(player.birth_date))/(86400000*365))} {this.props.t('years')}</div>
            </div>
        </div>)
    }

    render() {

        if (this.state.player) {
          return (
              <div className={'recent-players'}>
                  {this.props.added && <h2>{this.props.t("Blacklist added!")}</h2>}
                  {this.props.added === false && <h2>{this.props.t("Blacklist error!")}</h2>}
                  <div className="details">
                        <span className="title">{this.props.t("Player details")}:</span>
                      {this.renderPlayer(this.state.player)}
                  </div>
                  {this.props.added === null && <button className={'button'} onClick={this.props.buttonOK.bind(this,this.state.player)}>{this.props.t("Add to blacklist")}</button>}
                  {this.props.added === null && <button className={'button danger'} onClick={this.selectPlayer.bind(this,false)}>{this.props.t("Cancel")}</button>}
                  {this.props.added && <NavLink className={'button'} to={'/'}>{this.props.t("New operation")}</NavLink>}
                  {this.props.added === false && <button className={'button'} onClick={this.props.buttonOK.bind(this,this.state.player)}>{this.props.t("Retry")}</button>}
                  {this.props.added === false && <NavLink className={'actions'} to={'/actions'}>{this.props.t("Other actions")}</NavLink>}

              </div>
          )
        }
        return (
            <div className={'recent-players'}>
                <h2>{this.props.t("Select player")}</h2>

                {this.listPlayers()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recent_players: state.application.recent_players
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getRecentPlayers: (page) => dispatch(storeActions.getRecentPlayers(page)),
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(RecentPlayers)));
