import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './Blacklist.scss';
import TitleBar from '../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../store/actions";
import RecentPlayers from "../RecentPlayers/RecentPlayers";

class Blacklist extends Component {

	state = {
		step: null,
		player: false,
		added: null
	}

	componentDidMount() {

		if (!this.props.authenticated) {
			this.props.history.push("/login");
		}

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.blacklist !== this.props.blacklist) {
			this.setState({
				...this.state,
				added:this.props.blacklist.status
			})
		}
	}

	clickHandler() {
		if (this.state.player) {
			this.selectPlayer(false)
		} else {
			this.props.history.push('/');
		}
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	selectPlayer(player) {
		this.setState({
			...this.state,
			player: player,

		})
	}

	confirmBlacklist(player) {
		this.setState({
			...this.state,
			added:null
		})
		this.props.sendBlacklist(player.id);
	}

	render() {
		let cls = this.state.added ? ' success' : this.state.added === false ? ' error' : '';

		return (
			<div className={'blacklist-wizard' + cls}>
				<TitleBar
					pageTitle={this.props.t('Blacklist')}
					backButton={false}
					closeButton={true}
					backHandler={this.backHandler.bind(this)}
				/>
					<div className="blacklist-content">
						<RecentPlayers buttonOK={this.confirmBlacklist.bind(this)} added={this.state.added}/>
					</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.authentication.authenticated,
		blacklist: state.player.blacklist
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		sendBlacklist: (scan_id) => dispatch(storeActions.sendBlacklist(scan_id)),
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Blacklist)));
