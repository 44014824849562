import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as storeActions from "../../store/actions";

class Logout extends Component {


    componentDidMount() {
        this.props.logout();
        this.props.history.push('/login');
    }

    render() {

        return (
            <></>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(storeActions.logout()),
    };
};
export default (withRouter(connect(null, mapDispatchToProps)(Logout)));
