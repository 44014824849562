import React, { Component } from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import './ScanDocument.scss';
import * as storeActions from "../../../store/actions";
import {DialogTemplate} from "../../UI/Dialog/DialogTemplate";
import Webcam from "react-webcam";
import {Button} from "@material-ui/core";

class ScanDocument extends Component
{

    constructor(props) {
        super(props);
        this.webcamRef = React.createRef();
    }

    render() {

        return (
            <DialogTemplate
                open={this.props.open}
                className={`druid-scan-document-modal`}
                title={this.props.t('Verificare identitate')}
                icon={<img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS44NzQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNS44NzQgMjEiPg0KICA8ZyBpZD0icGhvdG8tY2FtZXJhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiPg0KICAgIDxnIGlkPSJHcm91cF83OTQiIGRhdGEtbmFtZT0iR3JvdXAgNzk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDQ2LjE1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF85MTEiIGRhdGEtbmFtZT0iUGF0aCA5MTEiIGQ9Ik0wLDUyLjU3NlY2My45OTJBMy4xNTksMy4xNTksMCwwLDAsMy4xNTgsNjcuMTVIMjIuNzE2YTMuMTU5LDMuMTU5LDAsMCwwLDMuMTU4LTMuMTU4VjUyLjU3NmEzLjAwNiwzLjAwNiwwLDAsMC0zLTNoLTQuMmwtLjEtLjQzOGEzLjgzOCwzLjgzOCwwLDAsMC0zLjc2LTIuOTgzSDExLjA2MkEzLjg0NywzLjg0NywwLDAsMCw3LjMsNDkuMTMzbC0uMS40MzhIM0EzLjAxLDMuMDEsMCwwLDAsMCw1Mi41NzZabTcuNzItMS43MTFhLjY0NC42NDQsMCwwLDAsLjYyOC0uNWwuMjE2LS45NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUtMS45OGgzLjc0NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUsMS45OGwuMjE2Ljk0YS42NDkuNjQ5LDAsMCwwLC42MjguNWg0LjcxNWExLjcxLDEuNzEsMCwwLDEsMS43MTEsMS43MTFWNjMuOTkyYTEuODY0LDEuODY0LDAsMCwxLTEuODY0LDEuODY0SDMuMTU4YTEuODY0LDEuODY0LDAsMCwxLTEuODY0LTEuODY0VjUyLjU3NkExLjcxLDEuNzEsMCwwLDEsMyw1MC44NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiIGZpbGw9IiNmZmYiLz4NCiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTQ1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTQ1IiBjeD0iMC44NjYiIGN5PSIwLjg2NiIgcj0iMC44NjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTExIDYuNjExKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfOTEyIiBkYXRhLW5hbWU9IlBhdGggOTEyIiBkPSJNMTQ3LjUzNCwxODYuMDE3YTUuNDMzLDUuNDMzLDAsMSwwLTUuNDM0LTUuNDMzQTUuNDQyLDUuNDQyLDAsMCwwLDE0Ny41MzQsMTg2LjAxN1ptMC05LjU3M2E0LjE0LDQuMTQsMCwxLDEtNC4xNCw0LjE0QTQuMTQ3LDQuMTQ3LDAsMCwxLDE0Ny41MzQsMTc2LjQ0NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQuNTk3IC0xNjguMzM4KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo="
                    alt="camera" />}
            >
                <div className="druid-scan-document-modal-message">
                    {this.props.t("Pozitioneaza cartea de identitate in chenar pentru citire cu camera")}
                    {this.props.open && <div className="webcam-wrapper">
                        <Webcam
                            audio={false}
                            height={window.innerHeight}
                            screenshotFormat="image/jpeg"
                            width={window.innerWidth}
                            ref={this.webcamRef}
                            videoConstraints={{
                                facingMode: "camera"
                            }}
                            onUserMediaError={() => {
                                if (this.props.inputOpenFileRef) {
                                    this.props.inputOpenFileRef.current.click();
                                }
                            }}
                        >
                            {() => (
                                <div className="overlay">
                                    <div className="title">{this.props.t('ID Card')}</div>
                                    <div className="actions">
                                        <Button
                                            onClick={() => {
                                                const canvasPhoto = this.webcamRef.current.getCanvas();

                                                canvasPhoto.toBlob((blob)=> {
                                                    const file = new File([blob], "filename.jpg",{type: "image/jpeg"});
                                                    this.props.scanDocument([file]);
                                                },'image/jpeg',1)
                                            }}
                                        >
                                            <img
                                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS44NzQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNS44NzQgMjEiPg0KICA8ZyBpZD0icGhvdG8tY2FtZXJhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiPg0KICAgIDxnIGlkPSJHcm91cF83OTQiIGRhdGEtbmFtZT0iR3JvdXAgNzk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDQ2LjE1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF85MTEiIGRhdGEtbmFtZT0iUGF0aCA5MTEiIGQ9Ik0wLDUyLjU3NlY2My45OTJBMy4xNTksMy4xNTksMCwwLDAsMy4xNTgsNjcuMTVIMjIuNzE2YTMuMTU5LDMuMTU5LDAsMCwwLDMuMTU4LTMuMTU4VjUyLjU3NmEzLjAwNiwzLjAwNiwwLDAsMC0zLTNoLTQuMmwtLjEtLjQzOGEzLjgzOCwzLjgzOCwwLDAsMC0zLjc2LTIuOTgzSDExLjA2MkEzLjg0NywzLjg0NywwLDAsMCw3LjMsNDkuMTMzbC0uMS40MzhIM0EzLjAxLDMuMDEsMCwwLDAsMCw1Mi41NzZabTcuNzItMS43MTFhLjY0NC42NDQsMCwwLDAsLjYyOC0uNWwuMjE2LS45NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUtMS45OGgzLjc0NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUsMS45OGwuMjE2Ljk0YS42NDkuNjQ5LDAsMCwwLC42MjguNWg0LjcxNWExLjcxLDEuNzEsMCwwLDEsMS43MTEsMS43MTFWNjMuOTkyYTEuODY0LDEuODY0LDAsMCwxLTEuODY0LDEuODY0SDMuMTU4YTEuODY0LDEuODY0LDAsMCwxLTEuODY0LTEuODY0VjUyLjU3NkExLjcxLDEuNzEsMCwwLDEsMyw1MC44NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiIGZpbGw9IiNmZmYiLz4NCiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTQ1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTQ1IiBjeD0iMC44NjYiIGN5PSIwLjg2NiIgcj0iMC44NjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTExIDYuNjExKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfOTEyIiBkYXRhLW5hbWU9IlBhdGggOTEyIiBkPSJNMTQ3LjUzNCwxODYuMDE3YTUuNDMzLDUuNDMzLDAsMSwwLTUuNDM0LTUuNDMzQTUuNDQyLDUuNDQyLDAsMCwwLDE0Ny41MzQsMTg2LjAxN1ptMC05LjU3M2E0LjE0LDQuMTQsMCwxLDEtNC4xNCw0LjE0QTQuMTQ3LDQuMTQ3LDAsMCwxLDE0Ny41MzQsMTc2LjQ0NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQuNTk3IC0xNjguMzM4KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo="
                                                alt="camera" />
                                            {this.props.t("Scan_2")}
                                        </Button>
                                    </div>

                                </div>

                            )}
                        </Webcam>

                    </div>}
                    <Button
                        onClick={() => {
                            if (this.props.inputOpenFileRef) {
                                this.props.inputOpenFileRef.current.click();
                            }
                        }} className={'upload-document'}
                    >
                        <img
                            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS44NzQiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyNS44NzQgMjEiPg0KICA8ZyBpZD0icGhvdG8tY2FtZXJhIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiPg0KICAgIDxnIGlkPSJHcm91cF83OTQiIGRhdGEtbmFtZT0iR3JvdXAgNzk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDQ2LjE1KSI+DQogICAgICA8cGF0aCBpZD0iUGF0aF85MTEiIGRhdGEtbmFtZT0iUGF0aCA5MTEiIGQ9Ik0wLDUyLjU3NlY2My45OTJBMy4xNTksMy4xNTksMCwwLDAsMy4xNTgsNjcuMTVIMjIuNzE2YTMuMTU5LDMuMTU5LDAsMCwwLDMuMTU4LTMuMTU4VjUyLjU3NmEzLjAwNiwzLjAwNiwwLDAsMC0zLTNoLTQuMmwtLjEtLjQzOGEzLjgzOCwzLjgzOCwwLDAsMC0zLjc2LTIuOTgzSDExLjA2MkEzLjg0NywzLjg0NywwLDAsMCw3LjMsNDkuMTMzbC0uMS40MzhIM0EzLjAxLDMuMDEsMCwwLDAsMCw1Mi41NzZabTcuNzItMS43MTFhLjY0NC42NDQsMCwwLDAsLjYyOC0uNWwuMjE2LS45NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUtMS45OGgzLjc0NGEyLjU1NCwyLjU1NCwwLDAsMSwyLjUsMS45OGwuMjE2Ljk0YS42NDkuNjQ5LDAsMCwwLC42MjguNWg0LjcxNWExLjcxLDEuNzEsMCwwLDEsMS43MTEsMS43MTFWNjMuOTkyYTEuODY0LDEuODY0LDAsMCwxLTEuODY0LDEuODY0SDMuMTU4YTEuODY0LDEuODY0LDAsMCwxLTEuODY0LTEuODY0VjUyLjU3NkExLjcxLDEuNzEsMCwwLDEsMyw1MC44NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC00Ni4xNSkiIGZpbGw9IiNmZmYiLz4NCiAgICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTQ1IiBkYXRhLW5hbWU9IkVsbGlwc2UgMTQ1IiBjeD0iMC44NjYiIGN5PSIwLjg2NiIgcj0iMC44NjYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuNTExIDYuNjExKSIgZmlsbD0iI2ZmZiIvPg0KICAgICAgPHBhdGggaWQ9IlBhdGhfOTEyIiBkYXRhLW5hbWU9IlBhdGggOTEyIiBkPSJNMTQ3LjUzNCwxODYuMDE3YTUuNDMzLDUuNDMzLDAsMSwwLTUuNDM0LTUuNDMzQTUuNDQyLDUuNDQyLDAsMCwwLDE0Ny41MzQsMTg2LjAxN1ptMC05LjU3M2E0LjE0LDQuMTQsMCwxLDEtNC4xNCw0LjE0QTQuMTQ3LDQuMTQ3LDAsMCwxLDE0Ny41MzQsMTc2LjQ0NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzQuNTk3IC0xNjguMzM4KSIgZmlsbD0iI2ZmZiIvPg0KICAgIDwvZz4NCiAgPC9nPg0KPC9zdmc+DQo="
                            alt="camera" />
                        {this.props.t("Selectati documentul din galerie")}
                    </Button>
                </div>
            </DialogTemplate>
        );
    }
}

const mapStateToProps = state => {

    return {
    }
};
const mapDispatchToProps = dispatch => {
    return {
        scanDocument: (file) => dispatch(storeActions.scanDocument(file)),
    }

}
export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(ScanDocument));
