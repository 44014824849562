/**
 * Created by PhpStorm.
 * User: Liviu
 * Date: 18/02/2019
 * Time: 17:14
 */
import React, {Component} from 'react';
import { withTranslation} from "react-i18next";

class Translate extends Component {
    render() {

        let translated = this.props.t(this.props.text);
        if (this.props.placeholder) {
            let placeholders = this.props.placeholder;
            translated = translated.replace(/\${(.*?)}/g, function(match, key) {
                if (placeholders[key] !== false && placeholders[key] !== null ) {
                    return unescape(placeholders[key]);
                } else {
                    return  match;
                }
            });
        }

        return (<span dangerouslySetInnerHTML={{__html: translated}} className={'translate'}></span>);
    }

    getText () {
        return 'test';
    }
}

export default withTranslation()(Translate);
