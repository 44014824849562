import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './Login.scss';
import TitleBar from '../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import PersonIcon from "@material-ui/icons/Person";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Button from "@material-ui/core/Button";
import IconNext from "@material-ui/icons/TrendingFlat";
import Secured from "@material-ui/icons/LockOpen";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import * as storeActions from "../../store/actions";
import logo from "../../assets/logo/newton-wide-darkbg.png";

class Login extends Component {

	state = {
		showPassword: false,
		email: "",
		password: "",
		operatorId: "",
		emailOk: null, // null
		passwordOk: null, // null
		operatorOk: null, // null
	}

	componentDidMount() {
		if (this.props.authenticated) {
			this.props.history.push('/');
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated) {
			this.props.history.push('/');
		}

		if (prevProps.auth_error !== this.props.auth_error && (this.state.passwordOk && this.state.emailOk) && this.props.auth_error) {
			this.setState({
				...this.state,
				email: "",
				password: "",
				operatorId: "",
				emailOk: false, // null
				passwordOk: null, // null
				operatorOk: null, // null
			})
		}

	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	handleClickShowPassword() {
		this.setState({
			...this.state,
			showPassword: !this.state.showPassword
		})
	}

	changeEmail(e) {
		this.setState({
			...this.state,
			email: e.target.value.trim(),
			emailOk: e.target.value.trim().length > 1,//EmailValidator.validate(e.target.value)
		})
	}

	changeOperatorId(e) {
		this.setState({
			...this.state,
			operatorId: e.target.value.trim(),
			operatorOk: e.target.value.trim().length  > 2
		})
	}

	changePassword(e) {
		this.setState({
			...this.state,
			password: e.target.value,
			passwordOk: e.target.value.length > 1,//PasswordValidator.validate(e.target.value)
		})
	}

	loginHandler() {
		this.props.login(this.state.email, this.state.password,this.state.operatorId);
	}

	render() {

		return (
			<div className={'login-wizard'}>
				<TitleBar
					pageTitle={this.props.t('Log in')}
					backButton={false}
				/>
				<div className="logo-hero">
					<img src={logo} alt={'Newton Vision'}/>
				</div>
				<form className={'form'} noValidate autoComplete="off" onSubmit={() => alert('submited')}>
					<div className={'field'}>
						<PersonIcon className={"icon-field"} />
						<TextField
							autoFocus
							error={this.state.emailOk === false || this.state.passwordOk === false}
							label={this.props.t("Username")}
							id={"username"}
							value={this.state.email}
							className={`input-field`}
							onChange={ this.changeEmail.bind(this) }
							helperText={this.state.emailOk === false || this.state.passwordOk === false ? this.props.t("Invalid username or password") : ""}
						/>
					</div>
					<div className={'field'}>
						<Secured className={"icon-field"} />
						<TextField
							error={this.state.operatorOk === false}
							label={this.props.t("Operator ID")}
							id={"operator-id"}
							value={this.state.operatorId}
							className={`input-field`}
							onChange={ this.changeOperatorId.bind(this) }
						/>
					</div>
					<div className="field">
						<Secured className={"icon-field"} />
						<TextField
							error={this.state.passwordOk === false}
							label={this.props.t("Password")}
							id={"password"}
							type={this.state.showPassword ? 'text' : 'password'}
							className={`input-field`}
							value={this.state.password}
							onChange={ this.changePassword.bind(this) }
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											edge="end"
											aria-label="Toggle password visibility"
											onClick={this.handleClickShowPassword.bind(this)}
										>
											{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>
					<div className={"field button-field"}>
						<Button disabled={!(this.state.emailOk && this.state.passwordOk && this.state.operatorOk)} variant="contained" size="large" color="primary" onClick={this.loginHandler.bind(this)}>
							{ this.props.t("Log in") }
							<IconNext/>
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.authentication.authenticated,
		auth_error: state.authentication.auth_error
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		login: (username, password, operatorId) => dispatch(storeActions.login(username,password,operatorId)),
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Login)));
