import {put, takeEvery} from "redux-saga/effects";
import {login} from "../actions/actionTypes";
import {finishLogin} from "../actions/login";
import ClientAPI from "../../ClientAPI/ClientAPI";

function* loginAgent (action)  {
    const axios = ClientAPI.getInstance();

    const response = yield axios({
        url: '/api/auth/login',
        method: 'post',
        data: action.data
    });

    try {
        if (!response) {
            throw new Error(`[ERROR] Login response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] Login response has no 'result' property`);
        }

        if (!response.result.hasOwnProperty('access_token')) {
            throw new Error(`[ERROR] Login response has no 'result.access_token' property`);
        }
        yield put(finishLogin(response.result))

    } catch (e) {
        yield put(finishLogin({error:true}))
    }



}


export default function* watchLoginSaga() {
    yield takeEvery(login.START, loginAgent);
}