import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import * as storeActions from "./../../store/actions";
import { Spring } from 'react-spring/renderprops';
import './TopMenu.scss';
import Button from "@material-ui/core/Button";

import camera from "./assets/photo-camera.svg";
import { ReactComponent as WinnerIcon } from "./assets/hamburger-menu-winner.svg";

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    flex: {
        flex: 1
    },
    menuButton: {
        marginRight: theme.spacing(0),
    },
    personButton: {
        marginLeft: theme.spacing(0),
        "&.flexDir .MuiIconButton-label": {
            flexDirection: "column"
        }
    },
    toolbarMargin: theme.mixins.toolbar,
    appTopToolbar: {
        paddingRight: "4px"
    }
});

class TopMenu extends Component {
    constructor(props) {
        super(props);

        this.topFrom = -100;
        this.topTo = 0;

        this.icons = {
            eldorado: (<img src={window.location.protocol + "//" + window.config.cdn_address + window.config.MOBILE_BRAND_LOGO} alt='logo' />),//Icons.get('casinoLogo', `casinoLogo ${this.props.classes.toolbarMargin}`),
        };
    }

    state = {
        topFrom: this.topFrom,
        topTo: this.topTo,
        position: 'fixed',
        openCheckModal: false,
        openLottoCheckModal: false,
        openWinnerFun: false,
        isWinnerFun: false,
        showScan: true,
    }

    logoClickHandler() {
        this.props.history.push("/");
    };

    render() {
        let logo = [];
        return (
            <Spring className={"headerTopMenu"}
                from={{ top: this.topFrom }}
                to={{ top: this.topTo }}>
                {springProps => (
                    <div className={"headerTopMenu"} style={springProps}>
                        <div className={this.props.classes.root}>
                            <AppBar position="fixed" className={"AppTopMenuBar"}>
                                <Toolbar className={this.props.classes.appTopToolbar}>
                                    <IconButton edge="start" className={`${this.props.classes.menuButton} topMenuButton leftMenuActionButton`} color="inherit" aria-label="Menu" onClick={this.props.openLeftMenu}>
                                        {this.icons.hamburgerIcon}
                                    </IconButton>
                                    {logo}
                                </Toolbar>
                            </AppBar>
                        </div>
                    </div>
                )}
            </Spring>
        );
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopMenu))));
