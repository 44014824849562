import {put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import {player} from "../actions/actionTypes";

export function* selfExclude(action) {
    const axios = ClientAPI.getInstance();
    let playerStore = ClientAPI.getStore().getState().player;
    try {
        let uploadForm = new FormData();

        uploadForm.append("files[]", dataURLtoFile(playerStore.info.signature,playerStore.scan_id));
        uploadForm.append('scan_id',playerStore.scan_id)
        uploadForm.append('player_id',playerStore.player_id)

        let selfExclusionSelected = playerStore.self_exclusion_selected?.id.toLowerCase();

        uploadForm.append('exclusion_option',selfExclusionSelected);

        let loginInfo = JSON.parse(window.localStorage.getItem('login'));
        uploadForm.append('tenant_id',loginInfo.tenant_id);

        const response = yield axios({
            url: '/api/profile/selfExclude',
            method: 'post',
            data: uploadForm,
            headers: {
                "Content-Type": "multipart/form",
            }
        });

        if (!response) {
            throw new Error(`[ERROR] DRUID SCAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] DRUID SCAN  response has no 'result' property`);
        }

        yield put(actions.selfExcludeComplete(response.result));
    } catch (error) {
        yield put(actions.selfExcludeComplete({success:false}));
        return;
    }
}

export function* sendBlacklist(action) {
    const axios = ClientAPI.getInstance();
    try {
        const response = yield axios({
            url: '/api/profile/blacklist',
            method: 'post',
            data: {
                scan_id: action.scanId
            }
        });

        if (!response) {
            throw new Error(`[ERROR] blacklist response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] blacklist response has no 'result' property`);
        }

        yield put(actions.sendBlacklistComplete(response.result));
    } catch (error) {
        yield put(actions.sendBlacklistComplete({success:false}));
        return;
    }
}

export default function* watchPlayerSaga() {
    yield takeEvery(player.SELF_EXCLUDE, selfExclude);
    yield takeEvery(player.SEND_BLACKLIST, sendBlacklist)
}

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}