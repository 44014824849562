import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Switch, Route, withRouter} from "react-router-dom";

import TopMenu from "./../components/TopMenu/TopMenu";
import "./App.scss";

import PageContent from "./PageContent";
import { withTranslation } from "react-i18next";
import NotFound from "../components/NotFound/NotFound";

import Head from "../components/Head/Head";

function App() {


    if (typeof window.config !== "undefined" && window.config.is404 === 1) {
        return <NotFound />;
    } else {
        return (
            <>
                <Head />
                <Switch>
                    <Route>
                        <div
                            className={
                                "App" +
                                (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
                                    ? " ios"
                                    : " android")
                            }
                        >
                            <Grid container justifyContent="space-between" className="App-body">
                                <PageContent />
                            </Grid>
                        </div>
                    </Route>
                </Switch>
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withTranslation()(withRouter(connect(null, mapDispatchToProps)(App)));
