/* eslint-disable */
export const ro = {
	"Operator ID": "ID Operator",
	"Password":"Parolă",
	"Scan": "Scanează",
	"Other actions": "Alte acțiuni",
	"Log in": "Intră în cont",
	"Add to exclusion list": "Adăugare restricție",
	"Logout": "Logout",
	"Main page": "Pagina principală",
	"Actions": "Acțiuni",
	"Choose exclusion period":"Alege perioada de timp pentru excludere",
	"Access to agencies will be restricted": "Accesul în sălile de joc va fi restricționat",
	"Cancel": "Anulare",
	"Restriction": "Restricție",
	"Sign here": "Semnează aici",
	"Result":"Rezultat",
	"Restriction added": "Restricție adăugată",
	"Exclusion expires at": "Excluderea expira la",
	"New restriction": "Restricție nouă",
	"Error": "Eroare",
	"ERROR": "EROARE",
	"Success": "Success",
	"SUCCESS": "SUCCCES",
	"Scan again": "Scanează din nou",
	"Exclusion": "Excludere",
	"I agree with closing my account and all the effects associated with this action": "Sunt de acord să îmi închid contul cu toate efectele asociate acestei acțiuni",
	"Reading document": "Citire Document",
	"Checking document...": "Se verifică documentul...",
	"The document is unclear. Try with another picture": "Documentul este neclar sau are informații acoperite. Încearcă cu o altă poză",
	"The document was scanned successfully": "Documentul a fost citit cu success",
	"The maximum file size is ${size}": "Dimensiunea maximă a fișierului nu trebuie sa depășească ${size}",
	"Blacklist added!": "Listare adăugată!",
	"Blacklist error!": "Eroare listare!",
	"Player details": "Detalii jucător",
	"Add to blacklist": "Adăugare în Blacklist",
	"New operation": "Operațiune nouă",
	"Retry": "Reîncearcă",
	"Select player": "Selectează jucător",
	"Exclusion verification": "Verificare excludere",
	"100% safe": "100% sigur",
	"years": "ani",
	"Error reading document!": "Eroare citire document!",
	"Scan error": "Eroare scanare",
	"Possible solutions": "Posibile soluții",
	"Age under 18": "Varsta sub 18 ani",
	"Start self-exclusion": "Incepe auto-excludere",
	"Access granted!": "Acces permis!",
	"Restricted client": "Client restriționat",
	"The access in location is restricted":"Accesul în locație nu este permis",
	"New verification": "Verificare nouă",
	"No player scans found in this timeframe": "Nicio scanare găsită în această perioadă",
	"Invalid username or password": "Credențialele sau parola sunt greșite",
	"Username": "Nume utilizator",
	"Exclusion from the game": "Excludere de la joc",
	"Day": "zi",
	"Days": "zile",
	"Month": "Lună",
	"Months": "Luni",
	"Select location": "Selectează locația",
	"Selected location": "Locație selectată",
	"Select": "Selectează",
	"Year": "An",
	"Years": "Ani",
	"Unlimited": "Nelimitat",
};
