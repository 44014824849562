import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    locations_list: false,
    location: false,
    loading: true,
    recent_players: false,
};

const authReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case actionTypes.application.LOCATIONS_RECEIVED: {
                draft.locations_list = action.data?.rows;
                draft.loading = false;
                break;
            }
            case actionTypes.application.SET_LOCATION: {
                draft.location = action.data;
                draft.locations_list = false;
                break;
            }
            case actionTypes.application.GET_RECENT_PLAYERS_COMPLETE:{
                draft.loading = false;
                draft.recent_players= action.data
                break;
            }
            case actionTypes.login.LOGOUT:{
                draft.locations = false;
                draft.loading = false
                break;
            }
            case actionTypes.login.START: {
                draft.locations = false;
                draft.loading = true;
                break;
            }
            case actionTypes.player.SELF_EXCLUDE:
            case actionTypes.application.GET_RECENT_PLAYERS:
            case actionTypes.player.SEND_BLACKLIST:
            case actionTypes.application.GET_LOCATIONS:
            {
                draft.loading = true;
                break;
            }
            case actionTypes.player.SELF_EXCLUDE_COMPLETE:
            case actionTypes.player.SEND_BLACKLIST_COMPLETE:
            case actionTypes.login.END:
                draft.loading = false;
                break;
            default:
                break;
        }
    });

export default authReducer;
