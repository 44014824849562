import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './Actions.scss';
import TitleBar from '../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../store/actions";
import logo from "../../assets/logo/newton-wide-darkbg.png";

class Actions extends Component {

	state = {
		passwordOk: null
	}

	componentDidMount() {
		if (!this.props.authenticated) {
			this.props.history.push('/login');
		}
	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	render() {

		return (
			<div className={'actions-content'}>
				<TitleBar
					pageTitle={this.props.t('Other actions')}
					// backHandler={this.backHandler.bind(this)}
					closeButton={true}
					backButton={false}
				/>
				<div className="content">
					<div className="container">
						<div className="logo-hero">
							<img src={logo} alt={'Newton Vision'}/>
						</div>
						<div className="buttons">
							<NavLink to={'/exclusion/1'} className={'button'}>{this.props.t("Add to exclusion list")}</NavLink>
							<NavLink to={'/blacklist/1'} className={'button'}>{this.props.t("Add to blacklist")}</NavLink>
						</div>
						<div className="bottom-actions">
							<NavLink to={'/logout'}>{this.props.t("Logout")}</NavLink>
							<NavLink to={'/'}>{this.props.t("Main page")}</NavLink>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.authentication.authenticated,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		login: (username, password) => dispatch(storeActions.login(username,password)),
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Actions)));
