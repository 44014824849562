let trans = {};
const configTranslate = {
    languages: ["en", "ro"],
    defaultLanguage: "ro",
    languageNames: {
        en: "English",
        ro: "Română"
    }
};
for (let i = 0; i < configTranslate.languages.length; i++) {
    try {
        trans[configTranslate.languages[i]] = { translations: require("./" + configTranslate.languages[i] + ".js")[configTranslate.languages[i]] }
    } catch (e) {
        console.error("A aparut o eroare la incarcarea fisierului de traduceri pentru limba:", configTranslate.languages[i]);
    }
}
export const translations = trans;