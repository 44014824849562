import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../../store/actions";
import './ExclusionSignature.scss';
import SignatureCanvas from "react-signature-canvas";

class ExclusionSignature extends Component {

    constructor(props) {
        super(props);
        this.sigCanvas = false;
    }

    state = {
        active: false,
        hideLabel: false
    }

    hideLabel() {
        this.setState({
            ...this.state,
            hideLabel: true
        })
    }

    endSign() {

        this.props.setSignature(this.sigCanvas.toDataURL());
    }



    render() {

        return (
            <>
                <div className={"signature-wrapper "+ (this.props.active ? ' active' : ' disabled')}>

                    <SignatureCanvas penColor='white' onBegin={this.hideLabel.bind(this)} onEnd={this.endSign.bind(this)}
                                     canvasProps={{width: 300, height: 150, className: 'sigCanvas'}} ref={(ref) => { this.sigCanvas = ref }} />
                    {this.props.label && !this.state.hideLabel &&
                        <div className={'label'}>
                            {this.props.label}
                        </div>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        player: state.player,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setSignature: (signature) => dispatch(storeActions.setSignature(signature)),
    };
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionSignature)));
