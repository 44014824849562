// Libs
import React, {Component, useEffect} from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Transition, animated } from 'react-spring/renderprops';
import * as easings from "d3-ease";

// Main Pages
import Home from "../components/Home/Home";

// Authentication
import Login from "../components/Login/Login";
import Actions from "../components/Actions/Actions";
import Exclusion from "../components/Exclusion/Exclusion";
import Blacklist from "../components/Blacklist/Blacklist";
import ScanResponse from "../components/DruidScanner/ScanResponse/ScanResponse";
import * as storeActions from "../store/actions";
import Logout from "../components/Logout/Logout";
import ExclusionResponse from "../components/Exclusion/ExclusionResponse/ExclusionResponse";

class PageContent extends Component {

    componentDidMount() {
        this.props.softLogin();
        this.props.getMaxUploadSize();
        this.listenRouteChange();
    }

    listenRouteChange() {
        
        this.props.history.listen((location) => {
                window.scrollTo({ top: 0, behavior: 'smooth' });// scroll to top;
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading) {
            this.loadingScreen(this.props.loading)
        }
        if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated === false) {
            this.props.history.push('/login');
        }
    }
    loadingScreen(show = true) {
        let el = document.getElementById('Loading');
        let inject = false;
        if (!el) {
            inject = true;
            el = document.createElement('div');
            el.id = "Loading";
        }
        if (show) {
            el.className = '';
        } else {
            el.className = 'hide';
        }
        if (inject) {

            document.body.appendChild(el);
        }
    }

    render() {
        const routes = [];

        let defaultRoute = <Route path={['/', '/home']} exact key={'route_default'} component={Home} />;
        routes.push(defaultRoute);

        let loginRoute = <Route path={'/login'} exact key={'route_login'} component={Login} />;
        routes.push(loginRoute);

        let logoutRoute = <Route path={'/logout'} exact key={'route_logout'} component={Logout} />;
        routes.push(logoutRoute);

        let actionsRoute = <Route path={'/actions'} exact key={'route_actions'} component={Actions} />;
        routes.push(actionsRoute);

        let exclusionRoute = <Route path={'/exclusion/:step'} exact key={'route_exclusion'} component={Exclusion} />;
        routes.push(exclusionRoute);

        let blacklistRoute = <Route path={'/blacklist/:step'} exact key={'route_blacklist'} component={Blacklist} />;
        routes.push(blacklistRoute);

        let scanResponse = <Route path={'/scan-response/:response/:type'} exact key={'route_sccan-response'} component={ScanResponse} />;
        routes.push(scanResponse);

        let exclusionResponse = <Route path={'/exclusion-response/:response'} exact key={'route_exclusion-reposne'} component={ExclusionResponse} />;
        routes.push(exclusionResponse);

        return (
            <div className={`pageContent`}>
                <div className={`animatedPageContent`}>
                    <Switch>
                        {routes}
                    </Switch>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        authenticated: state.authentication.authenticated,
        loading: state.application.loading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        softLogin: () => dispatch(storeActions.softLogin()),
        getMaxUploadSize: () => dispatch(storeActions.getMaxFileSize())
    };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(PageContent)));
