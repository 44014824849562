import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './Exclusion.scss';
import TitleBar from '../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import * as storeActions from "../../store/actions";
import DruidScanner from "../DruidScanner/DruidScanner";
import ExclusionPicker from "./Picker/ExclusionPicker";
import ExclusionSignature from "./Signature/ExclusionSignature";
import {Checkbox} from "@material-ui/core";
import logo from "../../assets/logo/newton-wide-darkbg.png";

class Exclusion extends Component {

	state = {
		step: null,
		signature_active: false,
	}

	componentDidMount() {

		if (!this.props.authenticated) {
			this.props.history.push('/login');
		}

		this.setState({
			...this.state,
			step:parseInt(this.props.history.location.pathname.replace('/exclusion/',''))
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.player.self_exclusion !== this.props.player.self_exclusion && this.state.step === 3) {
			if (this.props.player.self_exclusion.status) {
				this.props.history.push('/exclusion-response/ok');
			} else {
				this.props.history.push('/exclusion-response/error');
			}
		} else if(prevProps.location !== this.props.location) {
			let state = {...this.state};
			state.step = parseInt(this.props.history.location.pathname.replace('/exclusion/',''));
			if (state.step === 2) {
				state.signature_active = false;
				this.props.setSignature(null);
			}
			this.setState(state);
		}

	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {

		if (this.state.step === 1) {
			this.clickHandler();
		} else {
			this.props.history.push('/exclusion/' + (this.state.step - 1));
		}
	}

	toggleAgreement(e) {

		this.setState({
			...this.state,
			signature_active: e.target.checked
		})
	}

	getContent() {
		let comp = [];

		switch (this.state.step) {
			case 1:
				comp.push(
					<div className="exclusion-content" key={'exclusion-step-1'}>
						<div className="logo-hero"><img src={logo} alt={"Newton Vision"}/> </div>
						<DruidScanner type={'exclusion'}/>
					</div>);
				break;
			case 2:
				comp.push(<ExclusionPicker key={'exclusion-step-2'} />);
				comp.push(<div className="buttons" key={'exclusion-step-2-buttons'} ><NavLink to={'/exclusion/3'} className={'button'}>{this.props.t("Next")}</NavLink></div>)
				comp.push(<NavLink to={'/'} className={'actions'} key={'exclusion-step-2-main-page-url'} >{this.props.t("Main page")}</NavLink>)
				break;
			case 3:
				comp.push(<div className={'exclusion-step-3'}>
							<div className="error-band">{this.props.t("Start self-exclusion")}</div>
							<div className="signature-content">
								<h2>{this.props.player.info.first_name} {this.props.player.info.last_name}</h2>
								{this.props.player.self_exclusion_selected  && <div className="restriction">
									{this.props.t("Restriction")}
									<div className="value">
										{this.props.player.self_exclusion_selected.label}
									</div>
								</div>}
								<div className="terms">
									<label htmlFor="terms">
										<Checkbox onChange={this.toggleAgreement.bind(this)} id={'terms'}/>
										{this.props.t("I agree with closing my account and all the effects associated with this action")}
									</label>
								</div>
								<ExclusionSignature key={'exclusion-step-3'} active={this.state.signature_active} label={"Sign here"}/>
							</div>
						  	<div className="buttons" key={'exclusion-step-3-buttons'} >
								<NavLink to={'/exclusion/3'} className={'button' + (this.props.player.info.signature && this.state.signature_active ? "" : " disabled")} onClick={this.selfExclude.bind(this)}>{this.props.t("Next")}</NavLink>
							</div>
							<NavLink to={'/'} className={'actions'} key={'exclusion-step-3-main-page-url'} >{this.props.t("Main page")}</NavLink>
						</div>);
				break;
			default:
				// do nothing
				break;
		}

		return comp;
	}

	selfExclude() {

		this.props.selfExclude();
	}

	render() {

		return (
			<div className={'exclusion-wizard'}>
				<TitleBar
					pageTitle={this.props.t('Exclusion')}
					closeButton={true}
					backHandler={this.backHandler.bind(this)}
				/>
				<div className="container">
					{this.getContent()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authenticated: state.authentication.authenticated,
		player: state.player
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		selfExclude: () => dispatch(storeActions.selfExclude()),
		setSignature: (signature) => dispatch(storeActions.setSignature(signature)),
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Exclusion)));
