import * as actionTypes from "./actionTypes";
import ClientAPI from "../../ClientAPI/ClientAPI";

export const locationsReceived = (data) => {

    return dispatch => {
        dispatch({
            type: actionTypes.application.LOCATIONS_RECEIVED,
            data: data
        })

        if (typeof data.rows !== 'undefined') {
            if (data.rows.length == 0) {
                const location = data.rows[0];
                dispatch(setLocation(location));
            }
        }
    };
};

export const showLocationPopup = (locations) => {
    return {
        type:actionTypes.application.SET_LOCATIONS,
        data: locations
    }
}

export const setLocation = (location) => {
    let login = window.localStorage.getItem('login');
    if (login) {
        login = JSON.parse(login);
        login.location = location;
        window.localStorage.setItem('login',JSON.stringify(login));
        ClientAPI.setLocation(location.id);
    }
    return {
     type:actionTypes.application.SET_LOCATION,
     data: location
    }
}

export const getRecentPlayers = (page) => {
    return {
        type:actionTypes.application.GET_RECENT_PLAYERS,
        page: page
    }
}

export const setRecentPlayers = (data) => {
    return {
        type:actionTypes.application.GET_RECENT_PLAYERS_COMPLETE,
        data: data
    }
}