import React, { useState, useEffect, createRef } from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {NavLink, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import "./Home.scss";
import DruidScanner from "../DruidScanner/DruidScanner";
import Locations from "../Locations/Locations";
import logo from "../../assets/logo/newton-wide-darkbg.png";
import TitleBar from "../UI/TitleBar/TitleBar";

const Home = props => {
  const { t } = useTranslation();

  const loggedIn = () =>  {
    return props.authenticated;
  }
  if (!loggedIn()) {
    props.history.push('/login')
  }

  return (
    <div className={`home`}>
        <Locations/>
        <TitleBar
            pageTitle={props.t('Actions')}
            backButton={false}
        />
        <div className="container">

            <div className="logo-hero">
                <img src={logo} alt={'Newton Vision'}/>
            </div>
            <DruidScanner type={'access'}/>
            <NavLink to={'/actions'} className={'actions'}>{t('Other actions')}</NavLink>
        </div>
    </div>
  );
};

const mapStateToProps = state => {


  return {
    authenticated: state.authentication.authenticated
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)((Home))));
