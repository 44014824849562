import * as actionTypes from './../actions/actionTypes';
import { produce } from "immer";

const initialState = {
    inProgress: false,
};

const druidReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {

            case actionTypes.login.END: {
                Object.keys(action.data).forEach((key ) => {
                    draft[key] = action.data[key];
                })
                break;
            }
            case actionTypes.login.LOGOUT: {
                draft = initialState;
                break;
            }
            default:
                break;
        }
    });

export default druidReducer;
