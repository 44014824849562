import * as actionTypes from "./actionTypes";

export const selectSelfExclusion = (value) => {
    return {
        type: actionTypes.player.SELECT_SELF_EXCLUSION,
        data: value,

    };
};

export const setSignature = (data) => {
    return {
        type: actionTypes.player.SET_SIGNATURE,
        data: data,
    }
}

export const selfExclude = () => {

    return {
        type: actionTypes.player.SELF_EXCLUDE
    }
}
export const selfExcludeComplete = (result) => {

    return {
        type: actionTypes.player.SELF_EXCLUDE_COMPLETE,
        data: result
    }
}

export const sendBlacklist = (scanId) => {
    return {
        type: actionTypes.player.SEND_BLACKLIST,
        scanId: scanId
    }
}

export const sendBlacklistComplete = (data) => {
    return {
        type: actionTypes.player.SEND_BLACKLIST_COMPLETE,
        data: data
    }
}
