import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import './ExclusionResponse.scss';
import TitleBar from '../../UI/TitleBar/TitleBar';
import {withTranslation} from "react-i18next";
import DruidScanner from "../../DruidScanner/DruidScanner";
import stopHand from "../../../assets/icons/ic_round-back-hand.svg";

class ExclusionResponse extends Component {

	state = {
		response: null
	}

	componentDidMount() {

		if(!(this.props.player && this.props.player.first_name)) {
			return this.props.history.push('/');
		}
		this.parseUrl(this.props.history.location.pathname);

		this.props.history.listen(location => {
			this.parseUrl(location.pathname);
		});

	}
	parseUrl(pathname) {

		let url = pathname.split('/');
		this.setState({
			...this.state,
			response: url[2]
		});
	}

	clickHandler() {
		this.props.history.push('/');
	}

	backHandler() {
		// console.log('test1234', this.props);
		this.clickHandler();
	}

	getContent() {
		let comp = [];

		switch (this.state.response) {

			case "ok":
				comp.push(<>
						<div className="result" key={'locked-1'}>
							<div className="icon">
								<img src={stopHand} alt="STOP!"/>
							</div>
							<h1 className="name">{this.props.player.first_name} {this.props.player.last_name}</h1>
							<div className="details">
								<p>{this.props.t("Result")}</p>
								<h2>{this.props.t("Restriction added")}</h2>
							</div>
						</div>
						<div className="exclusion-expire" key={'locked-2'}>
							{this.props.player.self_exclusion?.expiry_date && <>
								<span>{this.props.t('Exclusion expires at')}:</span>
								<div className="time">
									{this.props.player.self_exclusion.expiry_date}
								</div>
							</>}
							<NavLink className={'button'} to={'/exclusion/1'}>{this.props.t("New restriction")}</NavLink>
						</div>
						<NavLink to={'/'} className={'actions'} key={'locked-3'}>{this.props.t("Main page")}</NavLink>
					</>)
				break;
			case "error":

						comp.push(<div className="result">
							<div className="icon"> </div>
							<h1 className="name">{this.props.player.first_name} {this.props.player.last_name}</h1>
							<div className="details">
								<p>{this.props.t("Result")}:</p>
								<h2>{this.props.t("Error")}</h2>
							</div>
						</div>);
						comp.push(<div className={'buttons'}>
							<DruidScanner type={'exclusion'} label={this.props.t('Scan again')}/>

							<NavLink to={"/"} className={'button'}>
								{this.props.t('Main page')}
							</NavLink>
						</div>)
				break;
			default:
				// do nothing;
				break;
		}
		return comp;
	}

	render() {
		return (
			<div className={`exclusion-response ${this.state.response}`}>
				<TitleBar
					pageTitle={this.props.t('Exclusion')}
					closeButton={true}
					backButton={false}
					backHandler={false}
				/>
				<div className="container">
					{this.getContent()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authentication: state.authentication,
		player: state.player.info
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
	};
};
export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionResponse)));
