import {put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions';
import ClientAPI from '../../ClientAPI/ClientAPI';
import {application} from "../actions/actionTypes";

export function* getLocations() {
    const axios = ClientAPI.getInstance();
    try {

        const response = yield axios({
            url: '/api/profile/locations',
            method: 'post',
            data: {
                "get": "locations"
            }
        });

        if (!response) {
            throw new Error(`[ERROR] DRUID SCAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] DRUID SCAN  response has no 'result' property`);
        }

        yield put(actions.locationsReceived(response.result.data));
    } catch (error) {
        yield put(actions.locationsReceived(false));
        return;
    }
}


export function* getRecentPlayers(action) {
    const axios = ClientAPI.getInstance();
    try {
        let now = new Date();
        let yesterday = new Date(now.getTime()-86400000);// 24 hours
        const response = yield axios({
            url: '/api/profile/listPlayers',
            method: 'post',
            data: {
                date_start: yesterday.toJSON(),
                date_end: now.toJSON(),
                page: action.page,
                page_limit: 1000,
                sort_direction: "DESC"
            }
        });

        if (!response) {
            throw new Error(`[ERROR] DRUID SCAN response is empty!`);
        }

        if (!response.hasOwnProperty('result')) {
            throw new Error(`[ERROR] DRUID SCAN  response has no 'result' property`);
        }

        yield put(actions.setRecentPlayers(response.result.data));
    } catch (error) {

        yield put(actions.setRecentPlayers(false));
        return;
    }
}

export default function* watchAppSaga() {
    yield takeEvery(application.GET_LOCATIONS, getLocations);
    yield takeEvery(application.GET_RECENT_PLAYERS, getRecentPlayers);
}