import * as actionTypes from "./actionTypes";

export const scanDocument = (files,type) => {
    return {
        type: actionTypes.druid.SCAN_DOCUMENT,
        files: files,
        scanType: type
    };
};

export const scanComplete = (data) => {
    return {
        type: actionTypes.druid.SCAN_COMPLETE,
        result: data
    };
};

export const getMaxFileSize = () => {
    return {
        type: actionTypes.druid.MAX_FILE_SIZE,
    };
};

export const receivedMaxFileSize = (size) => {
    return {
        type: actionTypes.druid.MAX_FILE_SIZE_RECEIVED,
        size: size
    };
};