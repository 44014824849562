export const player = {
    SELECT_SELF_EXCLUSION: "PLAYER_SELECT_SELF_EXCLUSION",
    SET_SIGNATURE: "PLAYER_SET_SIGNATURE",
    SELF_EXCLUDE: "PLAYER_SELF_EXCLUDE",
    SELF_EXCLUDE_COMPLETE: "PLAYER_SELF_EXCLUDE_COMPLETE",
    SEND_BLACKLIST: "PLAYER_SEND_BLACKLIST",
    SEND_BLACKLIST_COMPLETE: "PLAYER_SEND_BLACKLIST_COMPLETE"
};

export const application = {
    GET_LOCATIONS: "APP_GET_LOCATIONS",
    LOCATIONS_RECEIVED: "APP_LOCATIONS_RECEIVED",
    SET_LOCATION: "APP_SET_LOCATION",
    SET_LOCATIONS: "APP_SET_LOCATIONS",
    GET_RECENT_PLAYERS: "APP_GET_RECENT_PLAYERS",
    GET_RECENT_PLAYERS_COMPLETE: "APP_GET_RECENT_PLAYERS_COMPLETE"
};

export const login = {
    START: "LOGIN_START",
    END: "LOGIN_END",
    LOGOUT: "LOGIN_LOGOUT",
};

export const druid = {
    SCAN_DOCUMENT: "DRUID_SCAN_DOCUMENT",
    SCAN_COMPLETE: "DRUID_SCAN_COMPLETE",
    MAX_FILE_SIZE: "DRUID_MAX_FILE_SIZE",
    MAX_FILE_SIZE_RECEIVED: "DRUID_MAX_FILE_SIZE_RECEIVED"
}
