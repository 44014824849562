import * as actionTypes from "./actionTypes";
import ClientAPI from "../../ClientAPI/ClientAPI";
import {setLocation} from "./application";

export const login = (username, password, operatorId) => {
    return {
        type: actionTypes.login.START,
        data: {
            username: username,
            password: password,
            short_id: operatorId,
            generate_refresh_token: true
        },

    };
};

export const softLogin = () => {
    let data = window.localStorage.getItem('login');
    if (data) {
        data = JSON.parse(data);

        return triggerLoginEvent(data);
    }
    return {
        type: 'NO_EVENT'
    }
}

export const logout = () => {
    window.localStorage.removeItem('login');
    ClientAPI.clearAccessToken();
    ClientAPI.clearRefreshToken();

    return {
        type: actionTypes.login.LOGOUT
    }
}

export const finishLogin = (data) =>{
    if (!data.error) {
        window.localStorage.setItem('login',JSON.stringify(data));
    }

    return triggerLoginEvent(data);

}

const triggerLoginEvent = (data) => {
    if (data.hasOwnProperty('access_token')) {
        ClientAPI.saveAccessToken(data.access_token);
        ClientAPI.onAccessTokenFetched(data.access_token);
    }

    // handle new refresh token
    if (data.hasOwnProperty('refresh_token')) {
        ClientAPI.saveRefreshToken(data.refresh_token);
    }


    return dispatch => {

        dispatch({
            type: actionTypes.login.END,
            data: data
        });
        dispatch({
            type: actionTypes.druid.MAX_FILE_SIZE,
        });
        if (!data.hasOwnProperty('location')) {

            dispatch( {
                type: actionTypes.application.GET_LOCATIONS
            });
        } else {
            dispatch(setLocation(data.location))
        }
    };

}